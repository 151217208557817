export default async function fetchSearch(term:string, userId:string, apiAddress:string) {
    const link = `${apiAddress}/search_chats`;
    const body = {
        term,
        userId
    }
  
    const response = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    })

    return response.json()

  }
