import { useEffect, useState  } from "react";
import { onValue, ref } from "firebase/database";
import { db } from "../firebase";
import {
    Box,
    Button,
    Flex,
    Center,
    Text,
    Image,
} from "@chakra-ui/react";
import { usePDF } from 'react-to-pdf';
import { MessagesInterface } from "../configs";
import { FaRegFile } from 'react-icons/fa';
import ChatMessages from "../components/ChatMessages";

export default function ChatDisplayPage(){
    const [chatMessages, setChatMessages] = useState<any>();
    const [chatConfigs, setChatConfigs] = useState<any>();
    const [chatId, setChatId] = useState<string>('');
    const [allReferences, setAllReferences] = useState<string[]>([]);
    const { toPDF, targetRef } = usePDF({filename: 'chatsoc_conv.pdf'});

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const chatString = params.get("chatId");
        if (chatString) {
            const publicChatsRef = ref(db, 'publicChats');
            onValue(publicChatsRef, (snapshot) => {
                if (Object.keys(snapshot.val()).includes(chatString)) {
                    setChatId(chatString);
                    const chatConfigsRef = ref(db, 'chats/' + chatString);
                    onValue(chatConfigsRef, (snapshot) => {
                        const configs = snapshot.val()
                        if(configs) {
                            setAllReferences(configs.references);
                            setChatConfigs(configs);
                            const chatMsgsRef = ref(db, 'rawChatMessages/' + chatString);
                            onValue(chatMsgsRef, (snapshot) => {
                                const msgs = snapshot.val()
                                if (msgs) {
                                    setChatMessages(msgs)
                                }
                            })
                        }})
                }})
            }
    }, [])

    return (
        <Box overflowY="auto" bg="#ffffff" id="chat-container">
            <Box bg="#f1f1f1" borderBottom=".5px solid gray" padding=".5%" fontFamily={"Elza, sans-serif"}>
                <Text fontSize="20px" fontWeight={500} textAlign="center">{chatConfigs && chatConfigs.title}</Text>
                <Text fontSize="14px" fontWeight={300} color="gray" textAlign="center">{chatConfigs && new Date(chatConfigs.time).toDateString()}</Text>
            </Box>
            <Center marginTop="1%" marginBottom="-2%">
                <Box cursor="pointer" onClick={() => {window.location.href = 'https://www.sullivanoncomp.com/chatsoc'}} h="15%" w="15%">
                    <Image src="chatsoc_logo.svg" alt="ChatSoc Logo"    />
                    <Text textAlign="right">Learn more</Text>
                </Box>
            </Center>
            {chatMessages && <ChatMessages status={"completed"} targetRef={targetRef} chatMessages={chatMessages} currentChat={chatId} allReferences={allReferences} apiAddress='' />}
            <Center margin="2%">
                <Button onClick={() => {toPDF()}} marginTop="20px" colorScheme="gray" variant="outline">Download Chat as PDF</Button>
            </Center>
        </Box>

    )
}
