import { useState, useRef  } from "react";
import {
    Box,
    Flex,
    Spacer,
    Link,
    Tooltip,
    Avatar,
    IconButton,
    useToast,
    Image,
} from "@chakra-ui/react";
import { ref, remove, update } from "firebase/database";
import { db } from "../firebase";
import { FaThumbsUp, FaThumbsDown, FaVolumeUp, FaCopy, FaPause } from 'react-icons/fa';
import ReviewModal from "./ReviewModal"
import { MessagesInterface } from "../configs";
import fetchStreamAudio from "../helpers/fetchStreamAudio";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks';


interface AssistantBoxProps {
    box: MessagesInterface;
    idx: number;
    currentChat: string;
    allReferences: any[];
    showThumbs: boolean;
    apiAddress: string;
    status: string;
}



const AssistantBox: React.FC<AssistantBoxProps> = ({ box, idx, currentChat, allReferences, showThumbs, apiAddress, status }) => {
    const [openReviewModal, setOpenReviewModal] = useState<boolean>(false);
    const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
    const audioSourceRef = useRef<AudioBufferSourceNode|null>(null);
    const toast = useToast();

    function thumbsUp(box:MessagesInterface, idx:number){
        if(box.review && box.review.thumbs === 'up') {
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx + '/review')
            remove(thumbsDownRef)
        }else{
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx)
            update(thumbsDownRef, {review: {thumbs: 'up'}})
        }
    }
    
    
    
    function thumbsDown(box:MessagesInterface, idx:number){
        if(box.review && box.review.thumbs === 'down') {
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx + '/review')
            remove(thumbsDownRef)
        }else{
            const thumbsDownRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx)
            update(thumbsDownRef, {review: {thumbs: 'down'}})
        }
    }

    function getLabelFromUrl(url:string|undefined){
        if (url) {
            const anchor = url.split('?anchor=')[1];
            //Find the reference in which anchor is located
            const ref = allReferences.find((ref:any) => ref.url_anchor === anchor);
            if(ref){
                return `${ref.title} ${ref.subtitle ? '(' + ref.subtitle + (ref.heading ? ': ' + ref.heading : '') + ') ' : ''}`
            }
        }
        return
    }
    
    function prepareText(text:string){
        text = text.replace(/\\n/g, "\n");
        if (text.charAt(0) === '"' && text.charAt(text.length - 1) === '"') {
            text = text.slice(1, -1);
        }
        const refRegex = /[\u3010\uFF3B\[](\d+)[\u3011\uFF3D\]]/g;
        text = text.replace(refRegex, (match:any, idx:any) => {
            // Subtract 1 to adjust for zero-based indexing
            idx = parseInt(idx) - 1;
            if (idx >= 0 && idx < allReferences.length) {
                let url = '';
                let section = allReferences[idx].title ? allReferences[idx].title : allReferences[idx];
                if (/\d/.test(section.split(' ')[0])) {
                    section = section.split(' ')[0];
                    url = allReferences[idx].title ? `https://app.sullivanoncomp.com/soc/index/title/${section}?anchor=${allReferences[idx].url_anchor}` : `https://app.sullivanoncomp.com/soc/index/title/${section}`
                } else {
                    if (allReferences[idx].url_anchor && allReferences[idx].url_anchor.toLowerCase().includes('glossary')) {
                        url = `https://app.sullivanoncomp.com/glossary`;
                    } else {
                        url = `https://app.sullivanoncomp.com/calculators/${allReferences[idx].url_anchor}`
                        return ` [[SOC ${section} Calculator]](${url})`
                    }
                }
                return ` [[SOC Section ${section}]](${url})`;
            }
            return match;  // Return original match if idx is out of bounds
        });
        return text;
    }

    function copyMessage(message:string){
        //Parse message to substitute references ([1]) for their actual source
        message = prepareText(message);
        navigator.clipboard.writeText(message)
        toast({
            position: 'top',
            title: 'Message copied to clipboard',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
    }

    function displayText(box:any){    
        const text = prepareText(box.content[0].text);
        return (
            <Box
            fontSize="16px"
            lineHeight="180%"
            fontFamily="Bitter, serif"
            maxW="85%"
            fontWeight={400}
            marginLeft="2%"
            padding="1.5%"
            boxShadow="md"
            paddingLeft="3%"
            borderRadius="10px"
            bg="#F7F7F8"
            position="relative"
            >
            <ReactMarkdown
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={{
                a: ({node, href, ...props}) => (
                    <Tooltip label={getLabelFromUrl(href)}>
                    <Link
                        onClick={(e) => {
                        e.stopPropagation();
                        window.open(href, '_blank');
                        }}
                        color="#006f98"
                        {...props}
                    />
                    </Tooltip>
                ),
                h1: ({node, ...props}) => <Box as="h1" fontSize="24px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                h2: ({node, ...props}) => <Box as="h2" fontSize="20px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                h3: ({node, ...props}) => <Box as="h3" fontSize="18px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                h4: ({node, ...props}) => <Box as="h4" fontSize="16px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                h5: ({node, ...props}) => <Box as="h5" fontSize="14px" fontWeight="bold" marginBottom="0.5em" marginTop="0.5em" {...props} />,
                p: ({node, ...props}) => <Box as="p" marginBottom="1em" {...props} />,
                ul: ({node, ...props}) => <Box as="ul" paddingLeft="20px" marginBottom="1em" listStyleType="disc" {...props} />,
                ol: ({node, ...props}) => <Box as="ol" paddingLeft="20px" marginBottom="1em" listStyleType="decimal" {...props} />,
                li: ({node, ...props}) => <Box as="li" marginBottom="0.5em" {...props} />,
                hr: ({node, ...props}) => <Box as="hr" marginBottom="1em" {...props} />,
                blockquote: ({node, ...props}) => (
                    <Box
                    as="blockquote"
                    paddingLeft="1em"
                    borderLeft="4px solid #ccc"
                    marginBottom="1em"
                    fontStyle="italic"
                    {...props}
                    />
                ),
                code: ({node, ...props}) => (
                    <Box
                    as="code"
                    padding="0.2em 0.4em"
                    backgroundColor="#f5f5f5"
                    borderRadius="4px"
                    fontFamily="monospace"
                    {...props}
                    />
                ),
                }}
            >
                {text}
            </ReactMarkdown>
            {status === 'completed' && (
                <Flex margin="auto" position="relative" zIndex={1}>
                <Spacer />
                <Tooltip label={box.provider === 'openai' ? "Answered using OpenAI" : " Answered using Claude"} placement="top" borderRadius="10px" closeOnScroll={true}>
                    <Image marginRight="1%" h="18px" borderRadius="20px" src={box.provider === 'openai' ? "openai_logo.png" : "claude_logo.png"} />
                </Tooltip>
                <IconButton
                    size="sm"
                    onClick={() => {
                    copyMessage(text);
                    }}
                    aria-label="Copy answer"
                    colorScheme="gray"
                    variant="link"
                    icon={<FaCopy />}
                />
                <IconButton
                    onClick={() => {
                    streamAudio(box.content[0].content);
                    }}
                    size="md"
                    aria-label="Listen answer"
                    colorScheme="gray"
                    variant="link"
                    icon={!audioPlaying ? <FaVolumeUp /> : <FaPause />}
                />
                </Flex>
            )}
            </Box>
        );
    }
    

    async function streamAudio(text:string){
        if (audioSourceRef.current) {
            // Stop the current audio if it's playing
            audioSourceRef.current.stop();
            audioSourceRef.current = null;
            setAudioPlaying(false);
            return;
        }

        setAudioPlaying(true);
        try {
            await fetchStreamAudio(text, audioSourceRef, setAudioPlaying, apiAddress)
        } catch (error) {
            console.error('Error fetching the audio:', error);
            setAudioPlaying(false);
        }
    }

    return (
        <Flex padding="2%">
            <Tooltip label={`Completed in ${Math.round(box.duration)} seconds`} placement="right" borderRadius="10px" closeOnScroll={true}>
                <Avatar h="50px" w="50px" src="chat_avatar.png" />
            </Tooltip>
            <Flex w="70%">
                {displayText(box)}
                {showThumbs &&
                <Flex w="5%">
                    <IconButton id={`ChatSOC - Response Liked ${idx}`} onClick={() => {thumbsUp(box, idx);}} marginTop="auto" marginBottom="auto" marginLeft="10%" aria-label="Like" colorScheme="gray" variant={box.review && box.review.thumbs === 'up' ? "solid" : "outline"} borderRadius="20px" icon={<FaThumbsUp style={{ pointerEvents: 'none' }} color={box.review && box.review.thumbs === 'up' ? "green" : "black"} />} />
                    <IconButton id={`ChatSOC - Response Disliked ${idx}`} aria-label="Dislike" onClick={(e) => {thumbsDown(box, idx)}} marginTop="auto" marginBottom="auto" marginLeft="10%" colorScheme="gray" variant={box.review && box.review.thumbs === 'down' ? "solid" : "outline"} borderRadius="20px" zIndex={1} icon={<FaThumbsDown style={{ pointerEvents: 'none' }} color={box.review && box.review.thumbs === 'down' ? "red" : "black"} />} />
                </Flex>}
            </Flex>
            <ReviewModal openReviewModal={openReviewModal} setOpenReviewModal={setOpenReviewModal} currentChat={currentChat} idx={idx} />
        </Flex>
    );
}

export default AssistantBox;
