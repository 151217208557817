import react, { useState } from "react";
import {
    Flex,
    Input,
    Box,
    Spacer,
    Avatar,
    IconButton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Text,
} from "@chakra-ui/react";
import { ref, remove, update } from "firebase/database";
import { db } from "../firebase";
import { FaTrash, FaPlus } from "react-icons/fa";



interface UsersModalProps {
    windowAddUser: boolean;
    setWindowAddUser: react.Dispatch<react.SetStateAction<boolean>>;
    authorizedUsers: string[];
}

const UsersModal: React.FC<UsersModalProps> = ({ windowAddUser, setWindowAddUser, authorizedUsers }) => {
    const [newUserString, setNewUserString] = useState<string>('');

    function deleteUser(x:string){
        remove(ref(db, '/allowedUsers/' + x))
    }

    function addUser(){
        const email = newUserString.replaceAll('.', '|');
        if (!authorizedUsers.includes(email)){
            const usersRef = ref(db, 'allowedUsers')
            const newValues = {[email]:true}
            update(usersRef, newValues);
            setNewUserString('');
        }
    }


    return (
        <Modal size="lg" isOpen={windowAddUser} onClose={() => {setWindowAddUser(false); }}>
            <ModalOverlay />
            <ModalContent bg="#ffeec3" border="1px solid black">
            <ModalBody marginTop="5%">
                <Flex>
                    <Input border="1px solid black" value={newUserString} onChange={(e) => {setNewUserString(e.target.value)}} placeholder="Add user email" />
                    <IconButton border="1px solid black" onClick={() => {addUser();}} marginLeft="4%" aria-label="Add user" variant="outline" icon={<FaPlus />} />
                </Flex>
                <Text marginTop="3%" textAlign="center" fontSize="20px" fontWeight={300}>All Users</Text>
                <Box h="400px" overflow="auto" marginBottom="1%">
                {authorizedUsers.map((x, idx) => {
                    return <Flex key={idx} marginTop="2%">
                            <Avatar size="sm" />
                            <Text marginTop="auto" marginBottom="auto" marginLeft="1%" fontWeight={300}>{x.replaceAll("|", ".")}</Text>
                            <Spacer />
                            <IconButton onClick={() => {deleteUser(x);}} size="sm" icon={<FaTrash />} aria-label="Delete user" variant="outline" borderRadius="20px" colorScheme="red" />
                        </Flex>
                })}
                </Box>
            </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default UsersModal;