export default async function fetchOpenaiFiles(file: File, apiAddress:string) {
    const link = `${apiAddress}/upload_files`;
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(link, {
      method: 'POST',
      headers: {
        'Accept': 'application/json'
      },
      body: formData, // send FormData directly
    });

    const result = await response.json();
    return result;
}