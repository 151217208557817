import React, { useCallback, useMemo } from 'react';
import { Box, Button, Tooltip, IconButton, InputGroup, Input, InputLeftElement, Text, Spinner, Center, Flex, Select } from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';
import { BiMessageAlt, BiSearch } from "react-icons/bi";
import { BsLayoutSidebarInset } from "react-icons/bs";

interface Chat {
  title: string;
  // Add other chat properties here
}

interface SidebarProps {
  isDesktop: boolean|undefined;
  showBar: boolean;
  setShowBar: (showBar: boolean) => void;
  allChats: Record<string, Chat>;
  currentChat: string;
  setCurrentChat: (chatId: string) => void;
  startNewDialogue: () => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  matchedChats: string[];
  loadingSearch: boolean;
  setApiAddress: (apiAddress: string) => void;
  apiAddress: string;
  navigate: (path: string) => void;
}

// Separate chat button component to prevent unnecessary rerenders
const ChatButton: React.FC<{
  chatId: string;
  title: string;
  isCurrentChat: boolean;
  onClick: () => void;
}> = React.memo(({ chatId, title, isCurrentChat, onClick }) => (
  <Tooltip label={title}>
    <Button 
      id={`ChatSOC - Revisiting Chat ${chatId}`}
      size="sm"
      onClick={onClick}
      mb="2" 
      borderRadius="sm" 
      color={isCurrentChat ? "black" : "#E0E0E0"}
      justifyContent="flex-start"
      variant={isCurrentChat ? "solid" : "ghost"}
      w="100%" 
      leftIcon={<BiMessageAlt fontSize="15px" color={isCurrentChat ? "black" : "#E0E0E0"} />}
    >
      <Text
        w="100%"
        textAlign="left"
        style={{ WebkitMaskImage: "linear-gradient(to right, rgba(0,0,0,1) 70%, rgba(0,0,0,0))" }}
        fontSize="15px"
        fontWeight="400"
      >
        {title}
      </Text>
    </Button>
  </Tooltip>
));

const Sidebar: React.FC<SidebarProps> = React.memo(({
  isDesktop,
  showBar,
  setShowBar,
  allChats,
  currentChat,
  setCurrentChat,
  startNewDialogue,
  searchText,
  setSearchText,
  matchedChats,
  loadingSearch,
  setApiAddress,
  apiAddress,
  navigate
}) => {
  // Memoize handlers
  const handleNewDialogue = useCallback(() => {
    startNewDialogue();
    if (!isDesktop) {
      setShowBar(false);
    }
  }, [startNewDialogue, isDesktop, setShowBar]);

  const handleCloseSidebar = useCallback(() => {
    setShowBar(false);
  }, [setShowBar]);

  const handleApiChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setApiAddress(e.target.value);
  }, [setApiAddress]);

  const handleSearchChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, [setSearchText]);

  // Memoize chat button click handler creator
  const createChatClickHandler = useCallback((chatId: string) => () => {
    setCurrentChat(chatId);
    navigate(`/chat?id=${chatId}`);
    if (!isDesktop) {
      setShowBar(false);
    }
  }, [setCurrentChat, navigate, isDesktop, setShowBar]);

  // Memoize the chat list
  const chatList = useMemo(() => {
    const chatIds = searchText.length > 0 ? matchedChats : Object.keys(allChats);
    return chatIds.map((chatId) => (
      <ChatButton
        key={chatId}
        chatId={chatId}
        title={allChats[chatId].title}
        isCurrentChat={currentChat === chatId}
        onClick={createChatClickHandler(chatId)}
      />
    ));
  }, [searchText, matchedChats, allChats, currentChat, createChatClickHandler]);

  // Memoize API selection visibility
  const showApiSelection = process.env.REACT_APP_CURRENT_ENV !== 'production';
  const showSearch = Object.keys(allChats).length >= 2;

  return (
    <Box 
      h="100vh" 
      w={!isDesktop && showBar ? "100%" : "20%"} 
      bg="#17404e"
      borderRight="1.5px solid #5b5b5b"
      position="relative"
      fontFamily={'"Elza", sans-serif'}
    >
      <Box h="100vh" overflowY="auto" overflowX="hidden">
        <Flex>
          <Button
            w="100%"
            id="ChatSOC - New Chat Created"
            bg="#addbed"
            _hover={{ bg: "#ffc10e" }}
            borderRadius={0}
            borderColor="white"
            marginRight="2%"
            leftIcon={<AddIcon />}
            onClick={handleNewDialogue}
          >
            New Dialogue
          </Button>
          <Tooltip label="Close sidebar">
            <IconButton 
              onClick={handleCloseSidebar} 
              variant="ghost" 
              colorScheme="whiteAlpha" 
              aria-label="Close sidebar" 
              icon={<BsLayoutSidebarInset />} 
            />
          </Tooltip>
        </Flex>
        
        {showApiSelection && (
          <Box marginTop="7%" marginBottom="3%">
            <Text marginLeft="10%" fontWeight={600} color="white">Select API</Text>
            <Center>
              <Select 
                w="80%" 
                bg="white" 
                value={apiAddress} 
                onChange={handleApiChange} 
                id="api-select"
              >
                <option value="https://chatsocapi.sullivanoncomp.com">Production</option>
                <option value="https://staging-chatsocapi.sullivanoncomp.com">Staging</option>
                {process.env.REACT_APP_CURRENT_ENV === 'local' && (
                  <option value="http://localhost:8000">Local</option>
                )}
              </Select>
            </Center>
          </Box>
        )}

        {showSearch && (
          <Center>
            <InputGroup marginTop="8%" marginBottom="4%" w="90%" size="sm">
              <InputLeftElement marginTop=".3%" pointerEvents='none'>
                {loadingSearch ? <Spinner color="gray.300" size="sm" /> : <BiSearch color='gray.300' />}
              </InputLeftElement>
              <Input
                value={searchText}
                onChange={handleSearchChange}
                bg="white"
                borderRadius="20px"
                placeholder='Search prior conversations'
                id="search-box"
              />
            </InputGroup>
          </Center>
        )}

        {chatList}
      </Box>
    </Box>
  );
});

export default Sidebar;