export default async function fetchSendMessage(text:string, chatId:string, apiAddress:string) {
  const link = `${apiAddress}/chat`;
  const body = {
      text,
      chatId,
  }

  const response = await fetch(link, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  })

  return response.json()

}