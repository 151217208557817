import LoginPage from "./pages/Login"
import { auth } from "./firebase";
import { signInWithCustomToken } from "firebase/auth";
import { useEffect, useState } from "react";
import ChatPage from "./pages/Chat";
import ChatDisplayPage from "./pages/ChatDisplay";
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';



export function App(){
  const [userAuth, setUserAuth] = useState<boolean>(false);

  useEffect(() => {
    function receiveMessage(event:any) {
      if(event.data.chatSocToken) {
        signInWithCustomToken(auth, event.data.chatSocToken)
        .then(() => {
          setUserAuth(true);
        })
      }
    }
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, []);

  return(
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage userAuth={userAuth} />} />
        <Route path="/chat" element={userAuth ? <ChatPage /> : <LoginPage userAuth={userAuth} />} />
        <Route path="/chatHistory" element={<ChatDisplayPage />} />
      </Routes>
    </Router>
  )
}
