import { useEffect, useState } from "react";
import {
    Box,
    Center,
    Image,
    Input,
    Button,
    Flex,
  } from "@chakra-ui/react"
import { useNavigate } from 'react-router-dom';


type LoginPageProps = {
  userAuth: boolean
};

export default function LoginPage({ userAuth }: LoginPageProps) {
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    if(userAuth){
      navigate('/chat')
    }
  }, [userAuth])


    return (
        <Box minH="100vh" textAlign="center" fontSize="xl">
            <Center h="100vh" flexDirection={'column'}>
                <Image w="45%" src="chatsoc_logo.svg" />
                {process.env.REACT_APP_CURRENT_ENV !== 'production' &&
                <Flex marginTop="4%" w="40%">
                  <Input value={token} onChange={(e) => {setToken(e.target.value)}} placeholder="Insert token" id="token-input"/>
                  <Button onClick={() => {window.postMessage({"chatSocToken": token})}} marginLeft="2%" borderRadius="20px" variant="outline">→</Button>
                </Flex>
                }
            </Center>
        </Box>
    )
}
