import react, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalHeader,
    Textarea,
    ModalFooter,
    Button,
} from "@chakra-ui/react";
import { ref, update } from "firebase/database";
import { db } from "../firebase";



interface ReviewModalProps {
    openReviewModal: boolean;
    setOpenReviewModal: react.Dispatch<react.SetStateAction<boolean>>;
    currentChat: string;
    idx:number
}

const ReviewModal: React.FC<ReviewModalProps> = ({ openReviewModal, setOpenReviewModal, currentChat, idx }) => {
    const [reviewString, setReviewString] = useState<string>('');

    function leaveReview() {
        const reviewRef = ref(db, 'rawChatMessages/' + currentChat + '/' + idx + '/review/');
        update(reviewRef, {explanation: reviewString});
        setOpenReviewModal(false);
    }
    return (
        <Modal size="lg" isOpen={openReviewModal} onClose={() => {setOpenReviewModal(false); }}>
            <ModalOverlay />
            <ModalContent bg="#ffeec3" border="1px solid black">
                <ModalHeader>Leave a Review</ModalHeader>
                <ModalBody>
                    <Textarea h="250px" value={reviewString} onChange={(e) => { setReviewString(e.target.value); }} placeholder="Why wasn't this answer great?" id="review-textarea" />
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="orange" variant="outline" onClick={() => {leaveReview();}}>Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ReviewModal;