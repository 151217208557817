import React from 'react';
import { Box, Button, Tooltip, IconButton, InputGroup, Input, InputLeftElement, Text, Spinner, Center, Flex, Select } from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';
import { BiMessageAlt, BiSearch } from "react-icons/bi";
import { FaClipboard, FaEllipsisV, FaPaperPlane, FaBars, FaRegFile } from "react-icons/fa";
import { BsLayoutSidebarInset } from "react-icons/bs";

interface SidebarProps {
  isDesktop: boolean|undefined;
  showBar: boolean;
  setShowBar: (showBar:boolean) => void;
  allChats: any;
  currentChat: string;
  setCurrentChat: (chatId:string) => void;
  startNewDialogue: () => void;
  searchText: string;
  setSearchText: (searchText:string) => void;
  matchedChats: string[];
  loadingSearch: boolean;
  setApiAddress: (apiAddress:string) => void;
  apiAddress: string;
  navigate: (path:string) => void;
}

const Sidebar: React.FC<SidebarProps> = React.memo(({
  isDesktop,
  showBar,
  setShowBar,
  allChats,
  currentChat,
  setCurrentChat,
  startNewDialogue,
  searchText,
  setSearchText,
  matchedChats,
  loadingSearch,
  setApiAddress,
  apiAddress,
  navigate
}) => {
  return (
    <Box 
      h="100vh" 
      w={!isDesktop && showBar ? "100%" : "20%"} 
      bg="#17404e"
      borderRight="1.5px solid #5b5b5b"
      position="relative"
      fontFamily={'"Elza", sans-serif'}
    >
      {/* Sidebar Content */}
      <Box h="100vh" overflowY="auto" overflowX="hidden">
        <Flex>
          <Button
            w="100%"
            id="ChatSOC - New Chat Created"
            bg="#addbed"
            _hover={{ bg: "#ffc10e" }}
            borderRadius={0}
            borderColor="white"
            marginRight="2%"
            leftIcon={<AddIcon />}
            onClick={() => { startNewDialogue(); (!isDesktop && setShowBar(false)); }}
          >
            New Dialogue
          </Button>
          <Tooltip label="Close sidebar">
            <IconButton onClick={() => { setShowBar(false); }} variant="ghost" colorScheme="whiteAlpha" aria-label="Close sidebar" icon={<BsLayoutSidebarInset />} />
          </Tooltip>
        </Flex>
        
        {/* API Selection (Non-Production Only) */}
        {process.env.REACT_APP_CURRENT_ENV !== 'production' && (
          <Box marginTop="7%" marginBottom="3%">
            <Text marginLeft="10%" fontWeight={600} color="white">Select API</Text>
            <Center>
              <Select w="80%" bg="white" value={apiAddress} onChange={(e) => { setApiAddress(e.target.value) }} id="api-select">
                <option value="https://chatsocapi.sullivanoncomp.com">Production</option>
                <option value="https://staging-chatsocapi.sullivanoncomp.com">Staging</option>
                {process.env.REACT_APP_CURRENT_ENV === 'local' && <option value="http://localhost:8000">Local</option>}
              </Select>
            </Center>
          </Box>
        )}

        {/* Search Box */}
        {Object.keys(allChats).length >= 2 && (
          <Center>
            <InputGroup marginTop="8%" marginBottom="4%" w="90%" size="sm">
              <InputLeftElement marginTop=".3%" pointerEvents='none'>
                {loadingSearch ? <Spinner color="gray.300" size="sm" /> : <BiSearch color='gray.300' />}
              </InputLeftElement>
              <Input
                value={searchText}
                onChange={(e) => { setSearchText(e.target.value); }}
                bg="white"
                borderRadius="20px"
                placeholder='Search prior conversations'
                id="search-box"
              />
            </InputGroup>
          </Center>
        )}

        {/* Chat List */}
        {(searchText.length > 0 ? matchedChats : Object.keys(allChats)).map((chatId:string) => (
          <Tooltip label={allChats[chatId].title} key={chatId}>
            <Button 
              id={`ChatSOC - Revisiting Chat ${chatId}`}
              size="sm"
              onClick={() => { setCurrentChat(chatId); navigate(`/chat?id=${chatId}`); (!isDesktop && setShowBar(false)); }} 
              mb="2" 
              borderRadius="sm" 
              color={currentChat === chatId ? "black" : "#E0E0E0"}
              justifyContent="flex-start"
              variant={currentChat === chatId ? "solid" : "ghost"}
              w="100%" 
              leftIcon={<BiMessageAlt fontSize="15px" color={currentChat === chatId ? "black" : "#E0E0E0"} />}
            >
              <Text
                w="100%"
                textAlign="left"
                style={{ WebkitMaskImage: "linear-gradient(to right, rgba(0,0,0,1) 70%, rgba(0,0,0,0))" }}
                fontSize="15px"
                fontWeight="400"
              >
                {allChats[chatId].title}
              </Text>
            </Button>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
});

export default Sidebar;