import React from 'react';
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import AssistantBox from "../components/AssistantBox";
import { MessagesInterface } from "../configs";
import { FaRegFile } from "react-icons/fa";

interface ChatMessagesProps {
  targetRef: React.RefObject<HTMLDivElement>;
  chatMessages: MessagesInterface[];
  currentChat: string;
  allReferences: string[];
  apiAddress: string;
  status: string;
}

const ChatMessages: React.FC<ChatMessagesProps> = React.memo(({ status, targetRef, chatMessages, currentChat, allReferences, apiAddress }) => {
  return (
    <Box id="chat-container" ref={targetRef}>
      {chatMessages.map((x, idx) => (
        <Box key={`message-${idx}`}>
          {x.role === 'assistant' && x.content && x.content[x.content.length - 1].text && x.content[x.content.length - 1].text.length > 10 && (
            <AssistantBox
              box={x}
              idx={idx}
              currentChat={currentChat}
              allReferences={allReferences}
              showThumbs={true}
              apiAddress={apiAddress}
              status={status}
            />
          )}
          {x.role === 'user' && x.content[0].type === 'text' && (
            <Flex key={`message-${idx}`}>
              <Box
                fontFamily="Bitter, serif"
                padding="1%"
                fontSize="16px"
                lineHeight="28px"
                boxShadow="md"
                maxW="60%"
                marginTop="75px"
                fontWeight={400}
                color="gray.700"
                marginLeft="auto"
                borderRadius="10px"
                bg="#F7F7F8"
                marginRight="1%"
                textAlign="left">
                <Text>{x.content[0].text}</Text>
                {x.uploadedFiles && x.uploadedFiles.map((y, fileIdx) => (
                  <Button
                    key={`${y.id}-file-${fileIdx}`}
                    size="sm"
                    cursor="auto"
                    variant="outline"
                    leftIcon={<FaRegFile />}
                    marginTop="10px"
                  >
                    {y.title.split('/').pop()}
                  </Button>
                ))}
              </Box>
            </Flex>
          )}
        </Box>
      ))}
    </Box>
  );
});

export default ChatMessages;