import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBu6D8kJHkGjKGHRK8gSyglc7T-l740NpY",
  authDomain: "chatsoc-bcedc.firebaseapp.com",
  databaseURL: "https://chatsoc-bcedc-default-rtdb.firebaseio.com",
  projectId: "chatsoc-bcedc",
  storageBucket: "chatsoc-bcedc.appspot.com",
  messagingSenderId: "914751710097",
  appId: "1:914751710097:web:973b818299d72d63de7030",
  measurementId: "G-JFFEG0MCY4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const storage = getStorage(app);
