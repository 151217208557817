export default async function fetchStopMessage(chatId:string, apiAddress:string) {
    const link = `${apiAddress}/cancel_run`;
    const body = {
        chatId,
    }
  
    const response = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    })

    return response.json()

  }
