export default async function fetchAutocompleteQuestion(chatId:string, question:string, apiAddress:string) {
    const link = `${apiAddress}/complete_question`;
    const body = {
        chatId,
        question
    }
  
    const response = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    }).then((r) => r.json());

    return response;
  }

