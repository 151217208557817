export default async function fetchCreateChat(uid:string, apiAddress:string, provider:string) {
    const link = `${apiAddress}/create_chat`;
    const body = {
        uid,
        url: window.location.href,
        provider
    }
  
    const response = await fetch(link, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(body),
    }).then((r) => r.json());

    return response;
  }


