export default async function fetchStreamAudio(text:string, audioSourceRef: React.MutableRefObject<AudioBufferSourceNode | null>, setAudioPlaying: React.Dispatch<React.SetStateAction<boolean>>, apiAddress:string) {
    const link = `${apiAddress}/text-to-speech`;
    const body = {
        text,
    }
  
    const response = await fetch(link, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });

    const reader = response.body!.getReader();
    const audioContext = new (window.AudioContext || window.AudioContext)();
    const audioChunks = [];

    while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        audioChunks.push(value);
    }

    const audioBuffer = await audioContext.decodeAudioData(await new Blob(audioChunks).arrayBuffer());
    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);

    source.onended = () => {
        setAudioPlaying(false);
        audioSourceRef.current = null;
    };

    audioSourceRef.current = source;
    source.start();
  }